<template>
    <div class="attestation-department" v-if="status === 'success'">
        <template v-if="workersView.length > 0">
            <div class="attestation-department__box box">
                <table class="attestation-department__table">
                    <caption class="attestation-department__table-caption">Итого</caption>
                    <colgroup>
                        <col class="attestation-department__table-column">
                        <col class="attestation-department__table-column">
                        <col class="attestation-department__table-column">
                    </colgroup>
                    <thead>
                        <tr class="attestation-department__table-row attestation-department__table-row_head">
                            <th class="attestation-department__table-cell attestation-department__table-cell_head" scope="col">Число сотрудников</th>
                            <th class="attestation-department__table-cell attestation-department__table-cell_head" scope="col">Аттестованых</th>
                            <th class="attestation-department__table-cell attestation-department__table-cell_head" scope="col">Cредний результат</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="attestation-department__table-row attestation-department__table-row_body">
                            <td class="attestation-department__table-cell attestation-department__table-cell_body">
                                {{totalView.workers_count | intFormat}}
                            </td>
                            <td class="attestation-department__table-cell attestation-department__table-cell_body">
                                {{totalView.validated_percent | percentFloatFormat}}
                            </td>
                            <td class="attestation-department__table-cell attestation-department__table-cell_body">
                                {{totalView.average_validation | percentFloatFormat}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="attestation-department__box box">
                <table class="attestation-department__table attestation-department__table_striped">
                    <caption class="attestation-department__table-caption">Сотрудники</caption>
                    <colgroup>
                        <col class="attestation-department__table-column attestation-department__table-column_worker-name">
                        <col class="attestation-department__table-column attestation-department__table-column_worker-position">
                        <col class="attestation-department__table-column attestation-department__table-column_attestation-date">
                        <col class="attestation-department__table-column attestation-department__table-column_attestation-result">
                        <col class="attestation-department__table-column attestation-department__table-column_attestation-percent">
                    </colgroup>
                    <thead>
                        <tr class="attestation-department__table-row attestation-department__table-row_head">
                            <th class="attestation-department__table-cell attestation-department__table-cell_head attestation-department__table-cell_sortable"
                                scope="col"
                                @click="setSorting('name')"
                            >
                                <span class="attestation-department__table-value">Имя</span>
                                <svg-icon class="attestation-department__table-value attestation-department__table-value_icon"
                                    v-if="sorting.field === 'name' && sorting.direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="attestation-department__table-value attestation-department__table-value_icon"
                                    v-if="sorting.field === 'name' && sorting.direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>
                            <th class="attestation-department__table-cell attestation-department__table-cell_head attestation-department__table-cell_sortable"
                                scope="col"
                                @click="setSorting('position')"
                            >
                                <span class="attestation-department__table-value">Должность</span>
                                <svg-icon class="attestation-department__table-value attestation-department__table-value_icon"
                                    v-if="sorting.field === 'position' && sorting.direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="attestation-department__table-value attestation-department__table-value_icon"
                                    v-if="sorting.field === 'position' && sorting.direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>
                            <th class="attestation-department__table-cell attestation-department__table-cell_head attestation-department__table-cell_sortable"
                                scope="col"
                                @click="setSorting('date_of_validation')"
                            >
                                <span class="attestation-department__table-value">Дата аттестации</span>
                                <svg-icon class="attestation-department__table-value attestation-department__table-value_icon"
                                    v-if="sorting.field === 'date_of_validation' && sorting.direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="attestation-department__table-value attestation-department__table-value_icon"
                                    v-if="sorting.field === 'date_of_validation' && sorting.direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>
                            <th class="attestation-department__table-cell attestation-department__table-cell_head attestation-department__table-cell_sortable"
                                scope="col"
                                @click="setSorting('status')"
                            >
                                <span class="attestation-department__table-value">Аттестован</span>
                                <svg-icon class="attestation-department__table-value attestation-department__table-value_icon"
                                    v-if="sorting.field === 'status' && sorting.direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="attestation-department__table-value attestation-department__table-value_icon"
                                    v-if="sorting.field === 'status' && sorting.direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>
                            <th class="attestation-department__table-cell attestation-department__table-cell_head attestation-department__table-cell_sortable"
                                scope="col"
                                @click="setSorting('percentage')"
                            >
                                <span class="attestation-department__table-value">Результат</span>
                                <svg-icon class="attestation-department__table-value attestation-department__table-value_icon"
                                    v-if="sorting.field === 'percentage' && sorting.direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="attestation-department__table-value attestation-department__table-value_icon"
                                    v-if="sorting.field === 'percentage' && sorting.direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="attestation-department__table-row attestation-department__table-row_body"
                            v-for="worker in workersView" :key="worker.name"
                        >
                            <td class="attestation-department__table-cell attestation-department__table-cell_body">
                                {{worker.name}}
                            </td>
                            <td class="attestation-department__table-cell attestation-department__table-cell_body">
                                {{worker.position}}
                            </td>
                            <td class="attestation-department__table-cell attestation-department__table-cell_body">
                                {{worker.date_of_validation | dateTextLong}}
                            </td>
                            <td class="attestation-department__table-cell attestation-department__table-cell_body">
                                <span class="attestation-department__table-value"
                                    :class="`attestation-department__table-value_${worker.status ? 'positive' : 'negative'}`"
                                >
                                    {{worker.status | yesOrNo}}
                                </span>
                            </td>
                            <td class="attestation-department__table-cell attestation-department__table-cell_body">
                                {{worker.percentage | percentIntFormat}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </template>
        <template v-else>
            <p>Нет данных</p>
        </template>
    </div>
</template>

<script>
    import { mapState, mapMutations } from "vuex";
    import parseISO from "date-fns/parseISO";
    import { dateTextLong } from "@/helpers/daterange";
    import { isTradePoint } from "@/helpers/departments";
    import { isAdministratorPosition } from "@/helpers/positions";

    import {
        compareStrings,
        compareNumbers,
        compareBooleans,
        compareDates
    } from "@/helpers/compare";

    export default {
        name: "AttestationDepartment",
        props: {
            id: {
                type: String,
                required: true
            },
            filters: {
                type: Array,
                required: true
            },
            filterId: {
                type: String,
                required: true
            },
            userAttestationUnits: {
                type: Object,
                required: true
            },
            status: {
                type: String,
                required: true
            },
            isFiltersVisible: {
                type: Boolean,
                required: true
            },
            isReportTotalLinkVisible: {
                type: Boolean,
                required: true
            },
            departments: {
                required: true
            },
            calcWorkersTotal: {
                type: Function,
                required: true
            }
        },
        computed: {
            ...mapState({
                title: state => state.page.title,
                sorting: state => state.attestation.sortings.department
            }),
            department() {
                return this.departments?.find(({ id }) => id === this.id);
            },
            workersView() {
                return Object.values(this.department?.workers)?.map(worker => {
                    if (worker.date_of_validation && !(worker.date_of_validation instanceof Date)) {
                        worker.date_of_validation = parseISO(worker.date_of_validation);
                    }

                    return worker;
                })?.filter(worker => {
                    switch (this.filterId) {
                        case "administrator":
                            return this.isAdministratorPosition(worker.position);
                        case "ordinary":
                            return !this.isAdministratorPosition(worker.position);
                        default:
                            return true;
                    }
                })?.sort((worker1, worker2) => {
                    let a = worker1[this.sorting.field];
                    let b = worker2[this.sorting.field];

                    switch (this.sorting.field) {
                        case "name":
                        case "position":
                            return compareStrings(a, b, this.sorting.direction);
                        case "date_of_validation":
                            return compareDates(a, b, this.sorting.direction);
                        case "status":
                            return compareBooleans(a, b, this.sorting.direction);
                        default:
                            return compareNumbers(a, b, this.sorting.direction);
                    }
                });
            },
            totalView() {
                return this.calcWorkersTotal(this.workersView);
            }
        },
        methods: {
            ...mapMutations([
                "setTitle",
                "setAttestationSortingField",
                "setAttestationSortingDirection",
                "toggleReportTotalLink",
                "toggleAttestationFilters"
            ]),
            setSorting(field) {
                if (this.sorting.field === field) {
                    this.setAttestationSortingDirection({
                        table: "department",
                        direction: this.sorting.direction === "ASC" ? "DESC" : "ASC"
                    });
                } else {
                    this.setAttestationSortingField({
                        table: "department",
                        field
                    });
                }
            },
            isTradePoint,
            isAdministratorPosition
        },
        filters: {
            dateTextLong
        },
        created() {
            if (!this.userAttestationUnits?.ids?.includes(this.id)) {
                this.$router.push({
                    name: "Error401",
                    params: {
                        requestedResource: `Подразделение ${this.id}`
                    }
                });
            } else {
                if (this.isFiltersVisible && !this.isTradePoint(this.id)) {
                    this.toggleAttestationFilters(false);
                }

                let restaurantTitle = this.userAttestationUnits?.byId?.[this.id]?.title;

                if (restaurantTitle && !this.title.includes(restaurantTitle)) {
                    this.setTitle(`${this.title} "${restaurantTitle}"`);
                }

                if (this.userAttestationUnits?.ids?.length > 1 && !this.isReportTotalLinkVisible) {
                    this.toggleReportTotalLink(true);
                }
            }
        }
    }
</script>

<style lang="scss">
    .attestation-department__box {
        padding: 8px 16px 16px;
        overflow-x: auto;

        & + & {
            margin-top: 30px;
        }
    }
    .attestation-department__table {
        width: 100%;
    }
    .attestation-department__table-caption {
        padding: 8px 8px 0;
        font-size: 24px;
        font-weight: bold;
    }
    .attestation-department__table-column {
        &_report-date {
            width: 25%;
        }
        &_workers-count {
            width: 25%;
        }
        &_validated-percent {
            width: 25%;
        }
        &_average-attestation {
            width: 25%;
        }

        &_worker-name {
            width: 30%;
        }
        &_worker-position {
            width: 25%;
        }
        &_attestation-date {
            width: 20%;
        }
        &_attestation-result {
            width: 12.5%;
        }
        &_attestation-percent {
            width: 12.5%;
        }
    }
    .attestation-department__table-row {
        &_body {
            .attestation-department__table_striped &:nth-of-type(odd) {
                background-color: $background-gray-primary;
            }

            &:hover {
                background-color: $gray-form !important;
            }
        }
    }
    .attestation-department__table-cell {
        font-size: 20px;
        padding: 16px 8px;

        &_sortable {
            cursor: pointer;
        }
    }
    .attestation-department__table-value {
        vertical-align: middle;

        & + & {
            margin-left: 5px;
        }

        &_icon {
            width: 24px;
            height: 24px;
        }

        &_positive {
            color: green;
        }

        &_negative {
            color: red;
        }
    }
</style>
